<template lang="pug">
    div
        loader(v-if="$root.loader")
        template(v-else)
            header-component
            v-content
                v-snackbar(
                :timeout="3000"
                :top="true"
                :right="true"
                :color="accountColor"
                v-model="accountMsj")
                    | {{ accountText }}
                    v-btn(flat dark @click.native="accountMsj = false") {{ $t('cerrar') }}
                v-container.grid-list-xl(fluid)
                    h1 {{ $t('Mi cuenta')}}, {{ $root.profile.names }}
                    v-layout(row wrap)
                        v-flex(xs12 sm6)
                            h2.mb-2 {{$t('CAMBIAR FOTO DE PERFIL')}}
                            template(v-if="preload")
                                h3 {{$t('Cargando')}}...
                            template(v-else)
                                .img-file(v-if="photo.length")
                                    img(:src="photo")
                                    v-icon(@click='removePhoto') close
                                .img-file(v-else-if="photoUrl.length")
                                    img(:src="photoUrl")
                                .user-avatar(v-else style="width:200px;height:200px;")
                                    v-gravatar(:size="200" :email="step.email")
                                .mb-5.my-4
                                    v-btn.btn-file.mx-0.mr-2(color="success" small outline) 
                                        v-icon(left) file_upload
                                        | {{$t('Seleccionar foto')}}
                                        input(type='file' @change='fileChange($event)')
                                    v-btn.btn-file.mx-0.primary(v-if="photo.length" :loading="loadingPhoto" small @click="uploadPhoto") 
                                        | {{$t('Subir foto')}}
                            h2.mb-2.mt-4 {{$t('ACTUALIZAR TUS DATOS')}}
                            template(v-if="preload")
                                h3 {{$t('Cargando')}}...
                            template(v-else)
                                v-layout(row wrap)
                                    v-flex(xs12)
                                        v-text-field(v-model='step.names' :label='$t("Nombre(s)")' required)
                                    v-flex(xs12)
                                        v-text-field(v-model='step.surname' :label='$t("Primer apellido")' required)
                                    v-flex(xs12)
                                        v-text-field(v-model='step.surname2' :label='$t("Segundo apellido")')
                                    v-flex(xs12 v-if="hasMexico && $root.profile.type == 1")
                                        v-text-field(v-model.trim='step.username' :label='$t("ID")')
                                    v-flex(xs12)
                                        v-text-field(v-model.trim="step.email" :label="$t('Correo electrónico')" type="email" required)
                                    v-flex(xs12)
                                        v-select(
                                            v-model="step.countryid"
                                            :items='country'
                                            item-value="uuid"
                                            item-text="name"
                                            :label='$t("País")'
                                            append-icon="keyboard_arrow_down"
                                            autocomplete
                                            :filter="vFilter"
                                            required
                                            @input="step.stateid='',state=[],step.cityid='',city=[],step.zones=[],zones=[],zone=-1,fetchState()"
                                        )
                                    v-flex(xs12)
                                        v-select(
                                            v-model="step.stateid"
                                            :items='state',
                                            item-value="uuid"
                                            item-text="name"
                                            :label='$t("Estado/Departamento/Provincia")'
                                            append-icon="keyboard_arrow_down"
                                            autocomplete
                                            :filter="vFilter"
                                            required
                                            @input="step.cityid='',city=[],step.zones=[],zones=[],zonei=-1,fetchCity()"
                                        )
                                    v-flex(xs12)
                                        v-select(
                                            v-model="step.cityid"
                                            :items='city'
                                            item-value="uuid"
                                            item-text="name"
                                            :label='$t("Ciudad/Municipio")'
                                            append-icon="keyboard_arrow_down"
                                            autocomplete
                                            :filter="vFilter"
                                            required
                                            @input="step.zones=[],zones=[],zonei=-1,fetchZone()"
                                        )
                                    template(v-if="zones.length")
                                        v-flex(xs12 v-for="(zone, i) in zones" :key="'z'+i")
                                            v-select(
                                                v-model="step.zones[i]"
                                                :items='zone'
                                                item-value="uuid"
                                                item-text="name"
                                                :label='zone[0].division'
                                                append-icon="keyboard_arrow_down"
                                                autocomplete
                                                :filter="vFilter"
                                                :required="isRequired('zones')"
                                                @change="zonei=i"
                                                @input="fetchZone"
                                            )
                                    v-flex(xs12)
                                        v-text-field(:prefix="phone" v-model.trim='step.phone' :label='$t("Celular (WhatsApp)")' required)
                                    v-flex(xs12)
                                        v-select(
                                            v-model="step.gender"
                                            :items='gender'
                                            item-value="id"
                                            item-text="text"
                                            :label='$t("Género")'
                                            append-icon="keyboard_arrow_down"
                                            autocomplete
                                            :filter="vFilter"
                                            required
                                        )
                                    v-flex(xs12)
                                        v-dialog(ref='dialogBirthdate' persistent v-model='modalBirthdate' lazy full-width width='290px' :return-value.sync='step.birthdate')
                                            v-text-field(slot='activator' :label='$t("Fecha de nacimiento")' v-model='step.birthdate' readonly hide-details)
                                            v-date-picker(
                                                :locale="$root.currentLanguage" 
                                                v-model='step.birthdate' 
                                                ref="birthdate" 
                                                scrollable
                                                :min="minBirthdateDate"
                                                :max="maxBirthdateDate"
                                            )
                                                v-spacer
                                                v-btn(flat color='primary' @click='modalBirthdate = false') {{ $t('Cancelar') }}
                                                v-btn(flat color='primary' @click='$refs.dialogBirthdate.save(step.birthdate)') {{$t('OK')}}
                                    v-flex(xs12)
                                        v-text-field(v-model='step.document' :label='$t("Número de documento de identificación")')
                                    
                                    v-flex(xs12 v-for="(c, i) in customForm" :key="'c'+i")
                                        v-text-field(v-if="c.type == 1" v-model="step.custom[c.uuid]" :label="$t(c.placeholder)" type="text" :required="c.required")
                                        v-text-field(multi-line v-if="c.type == 5" v-model="step.custom[c.uuid]" :label="$t(c.placeholder)" type="text" :required="c.required")
                                        template(v-if="c.type == 2")
                                            label.d-block.mb-1 {{ c.placeholder }}{{c.required ? '&bull;':''}}
                                            v-checkbox(v-for="(opt, i) in c.options" :key="'opt'+i" v-model="step.custom[c.uuid]" :label="(typeof opt == 'string' ? opt : opt.option)" :value="(typeof opt == 'string' ? opt : opt.option)")
                                        template(v-if="c.type == 4")
                                            v-select(clearable v-if="c.depends == null ? true : !c.depends.length" v-model="step.custom[c.uuid]" :return-object="true" :items="c.options" item-value="uuid" item-text="option" :label="c.placeholder" autocomplete :filter="vFilter" append-icon="keyboard_arrow_down" :required="c.required" @input="computedDepends(c, $event)")
                                            v-select(clearable v-else v-model="step.custom[c.uuid]" :items="c.hasOwnProperty('coptions') ? c.coptions : []" :required="c.required" item-value="uuid" item-text="option" :label="c.placeholder" :return-object="true" autocomplete :filter="vFilter" append-icon="keyboard_arrow_down" @input="computedDepends(c, $event)")
                                        v-radio-group(v-if="c.type == 3" v-model="step.custom[c.uuid]")
                                            label {{ c.placeholder }}{{c.required ? '&bull;':''}}
                                            v-radio(v-for="(opt, i) in c.options" :key="'opt2'+i" :value="(typeof opt == 'string' ? opt : opt.option)" :label="(typeof opt == 'string' ? opt : opt.option)")
                                    
                                    //- Grupo de WhatsApp
                                    v-flex(xs12 v-if="wpgroupEnabled && wpgroups.length")
                                        v-select(
                                            v-model="step.wpgroup"
                                            :items='wpgroups'
                                            item-value="uuid" 
                                            item-text="name"
                                            :label='$t("Grupo de WhatsApp")'
                                            append-icon="keyboard_arrow_down" 
                                            autocomplete
                                            :filter="vFilter"
                                        )

                                    //- Campos visibles (Rodolfistas)
                                    template(v-if="profile.isRodolfista")
                                        v-flex(xs12 v-if="isAvailable('field_area')")
                                            v-select(
                                                v-model="step.field_area"
                                                :items='field_area'
                                                item-value="uuid" 
                                                item-text="name" 
                                                :required="isRequired('field_area')"
                                                :label='$t("Área de estudio")'
                                                append-icon="keyboard_arrow_down" 
                                                autocomplete
                                                :filter="vFilter"
                                            )
                                        v-flex(xs12 v-if="isAvailable('field_other_area')")
                                            v-text-field(v-model='step.field_other_area' :label='$t("Otra área de estudio")')
                                        v-flex(xs12 align-center)
                                            v-layout.pa-3(align-center hide-details)
                                                v-checkbox(v-model="step.inschoolgroup")
                                                p.ml-2(style="width: 90%;font-size:14px;")
                                                    | {{$t('Da clic en el cuadro si deseas ampliar información de tus estudios.')}}
                                        template(v-if="step.inschoolgroup")
                                            v-flex(xs12 v-if="isAvailable('field_school')")
                                                h3 {{ $t('Datos del colegio donde estudió') }}
                                            v-flex(xs12 v-if="isAvailable('field_state')")
                                                v-select(
                                                    v-model="step.field_state"
                                                    :items='field_state' 
                                                    :label='$t("Departamento donde está ubicado su colegio")' 
                                                    append-icon="keyboard_arrow_down" 
                                                    autocomplete
                                                    :filter="vFilter"
                                                    item-value="uuid" 
                                                    item-text="name" 
                                                    :required="isRequired('field_state')"
                                                    @input="fetchFieldCity"
                                                )
                                            v-flex(xs12 v-if="isAvailable('field_city')")
                                                v-select(
                                                    v-model="step.field_city"
                                                    :items='field_city' 
                                                    :label='$t("Ciudad donde está ubicado su colegio")' 
                                                    append-icon="keyboard_arrow_down" 
                                                    autocomplete
                                                    :filter="vFilter"
                                                    item-value="uuid" 
                                                    item-text="name" 
                                                    :required="isRequired('field_city')"
                                                    @input="fetchFieldSchool"
                                                )
                                            v-flex(xs12 v-if="isAvailable('field_school')")
                                                v-select(
                                                    v-model="step.field_school"
                                                    :items='field_school'
                                                    :label='$t("Nombre de su colegio")' 
                                                    append-icon="keyboard_arrow_down"
                                                    autocomplete
                                                    :filter="vFilter"
                                                    item-value="uuid" 
                                                    item-text="name" 
                                                    :required="isRequired('field_school')"
                                                )
                                            v-flex.mt-4(xs12 v-if="isAvailable('field_institution')")
                                                h3 {{ $t('Datos de la Universidad donde estudió o estudia.') }}
                                            v-flex(xs12 v-if="isAvailable('field_institution')")
                                                v-select(
                                                    v-model="step.field_institution"
                                                    :items='field_institution'
                                                    :label='$t("Universidad donde estudió o estudia")' 
                                                    append-icon="keyboard_arrow_down"
                                                    autocomplete
                                                    :filter="vFilter"
                                                    item-value="uuid" 
                                                    item-text="name" 
                                                    :required="isRequired('field_institution')"
                                                )
                                            v-flex(xs12 v-if="isAvailable('field_grade')")
                                                v-text-field(v-model='step.field_grade' :label='$t("Digita el nombre de los grados recibidos o por recibir de pregrado y/o postgrado")')

                                    v-flex(xs12)
                                        v-text-field(v-model.trim="step.remail" :label="$t('Confirma tu correo electrónico')" type="email" required)
                                    v-flex(xs12)
                                        v-text-field(
                                            v-model="step.password" 
                                            :label="$t('Nueva contraseña')"
                                            :append-icon="e1 ? 'visibility' : 'visibility_off'"
                                            :append-icon-cb="() => (e1 = !e1)"
                                            :type="e1 ? 'password' : 'text'")
                                        small {{$t('Cambia tu contraseña para este sitio')}}
                                v-btn.primary(depressed small :loading="loadingBtn" @click="update") {{$t('Actualizar')}}
                        v-flex(xs12 sm6 v-if="$root.profile.type == 1")
                            h2.mb-2 {{$t('OPCIONES DE TU CUENTA')}}
                            v-btn.primary(depressed small @click="deleteAccount=true") {{$t('Eliminar mi cuenta')}}
            v-dialog(v-model='deleteAccount' max-width='700px')
                v-form
                    v-card
                        v-card-title.pb-0.justify-center {{$t('Eliminar mi cuenta')}}
                        v-card-text.text-xs-center
                            h3 {{$t('¿Estas seguro de eliminar tu cuenta?, se perderá toda tu información y no podrás recuperar tu cuenta')}}
                            v-divider.my-2
                            v-checkbox(:value="1" v-model="reason" :label="$t('Estoy recibiendo mucha publicidad')")
                            v-checkbox(:value="2" v-model="reason" :label="$t('Alguien más se registró por mi')")
                            v-checkbox(:value="3" v-model="reason" :label="$t('Ya no sigo interesado en hacer parte de la red')")
                        v-card-actions.pt-0
                            .btns.btns-center
                                v-btn(small color='primary' @click.stop='deleteAccount=false') {{$t('Cancelar')}}
                                v-btn(large color='accent' :loading="loadingDelete" @click="deleteAccountM") {{$t('Eliminar')}}
</template>

<script>

    import auth from 'mixins/auth'
    import config from 'src/config'
    import Vue from 'vue'
    import field from 'mixins/field'

    export default {
        mixins: [auth, field],
        metaInfo() {
            return {
                title: this.$t('Mi cuenta')
            }
        },
        data(){
            return {
                reason       : 3,
                deleteAccount: false,
                loadingDelete: false,
                accountMsj   : false,
                accountColor : '',
                accountText  :  '',
                step: {},
                e1: true,
                modalBirthdate: false,
                state: [],
                city: [],
                country: [],
                zones: [],
                zonei: -1,
                step : {
                    username : '',
                    names    : '',
                    surname  : '',
                    surname2 : '',
                    email    : '',
                    remail   : '',
                    password : '',
                    phone    : '',
                    countryid: '',
                    stateid  : '',
                    cityid   : '',
                    gender   : '',
                    birthdate: null,
                    zones    : [],
                    document : '',
                    custom   : {},
                    inschoolgroup: false,

                    // Campos red rodolfistas
                    field_state      : '',
                    field_city       : '',
                    field_school     : '',
                    field_institution: '',
                    field_grade      : '',
                    field_area       : '',
                    field_other_area : '',

                    // Grupo de WhatsApp
                    wpgroup: ''
                },
                wpgroups: [],
                photo: '',
                photoUrl: '',
                photoObj: '',
                loadingPhoto: false,
                preload: false,
                loadingBtn: false,
                customForm: [],
                availableRow: []
            }
        },
        computed: {
            profile(){
                return this.$root.profile
            },
            phone(){
                let country = this.country.find((item) => item.uuid == this.step.countryid)
                return country ? country.tel : ''
            },

            // Existe méxico
            hasMexico(){
                return this.country.some(c => c.uuid == 'd7e944ac-67a8-52b3-a303-6c520f928d69')
            },

            // Verificar estado para busqueda de grupo de WhatsApp
            wpgroupEnabled(){

                if(this.profile.type != 1){
                    return
                }

                let enabled = new Date()

                if(this.isAvailable('countryid') && !this.step.countryid){
                    enabled = false
                }

                if(this.isAvailable('stateid') && !this.step.stateid){
                    enabled = false
                }

                if(this.isAvailable('cityid') && !this.step.cityid){
                    enabled = false
                }

                if(this.isAvailable('gender') && this.step.gender === 0 ? false : !this.step.gender){
                    enabled = false
                }

                if(this.isAvailable('birthdate') && !this.step.birthdate){
                    enabled = false
                }

                return enabled
            }
        },
        watch: {
            modalBirthdate (val) {
                val && this.$nextTick(() => (this.$refs.birthdate.activePicker = 'YEAR'))
            },
            profile: {
                async handler(){

                    if(!Object.keys(this.profile).length){
                        return
                    }

                    this.preload = true

                    if(this.profile.photo != null && this.profile.photo.length){
                        this.photoUrl = config.uploaduser + this.profile.photo 
                    }

                    this.step.names     = this.profile.simple_names
                    this.step.surname   = this.profile.surname
                    this.step.surname2  = this.profile.surname2
                    this.step.email     = this.profile.email
                    this.step.countryid = this.profile.countryid
                    this.step.username  = this.profile.username
                    
                    await this.fetchState()
                    this.step.stateid = this.profile.stateid
                    
                    await this.fetchCity()
                    this.step.cityid = this.profile.cityid

                    this.step.phone = this.profile.phone
                    this.step.gender = this.profile.gender
                    this.step.birthdate = this.$moment(this.profile.birthdate).format('YYYY-MM-DD')
                    this.step.document = this.profile.document

                    await this.fetchZone()

                    if(this.profile.zones.length){

                        let zone  = this.profile.zones[0]
                        let zones = this.profile.zones.slice(1, this.profile.zones.length)
                        this.step.zones.splice(0, 1, zone)

                        if(!zones.length){

                            this.zonei = 0
                            await this.fetchZone(zone)
                        }

                        let zlen = zones.length
                        for(let i = 1, j=0; i <= zlen; i++, j++){

                            let parent = this.step.zones.slice(-1)[0]
                            this.zonei = i

                            this.step.zones.push(zones[j])

                            let push_empty_item = zlen == i ? false : true
                            await this.fetchZone(parent, push_empty_item)
                        }
                    }

                    this.step.custom        = this._.cloneDeep(this.profile.custom)
                    this.step.inschoolgroup = this.profile.inschoolgroup ? true : false
                    this.step.wpgroup       = this.profile.wpgroup

                    this.fetchForm()
                    this.preload = false
                },
                deep: true
            },
            wpgroupEnabled(){

                if(this.wpgroupEnabled){

                    let data = {
                        countryid: this.step.countryid,
                        stateid  : this.step.stateid,
                        cityid   : this.step.cityid,
                        gender   : this.step.gender,
                        birthdate: this.step.birthdate
                    }

                    this.$api(this, (xhr) => {
                        xhr.get('/wpgroup/register', {
                            params: {
                                networkid : this.profile.networkid,
                                filter    : JSON.stringify(data)
                            }
                        }).then((r) => {

                            let data = r.data
                            if(data.response){
                                this.wpgroups = data.data
                            }else{
                                this.wpgroups = []
                            }

                        }).catch(() => {})
                    })
                }
            }
        },
        async mounted(){
			
            this.fetchCountry()
		},
        methods: {

            fetchCountry(){

                return new Promise((resolve) => {
                    this.$api(this, (xhr) => {
                        xhr.get('/country', {
                            params: {ab: 1, networkid: this.networkid }
                        }).then((r) => {

                            let data = r.data
                            if(data.response){
                                this.country = data.data
                            }
                            resolve()
                        }).catch(() => {})
                    })
                })
            },

            fetchState(){

                return new Promise((resolve) => {
                    this.$api(this, (xhr) => {
                        xhr.get('/state', {
                            params: {
                                countryid: this.step.countryid,
                                ab: 1, 
                                networkid: this.networkid
                            }
                        }).then((r) => {

                            let data = r.data
                            if(data.response){
                                this.state = data.data
                            }
                            resolve()
                        }).catch(() => {})
                    })
                })
            },

            fetchCity(){

                return new Promise((resolve) => {
                    this.$api(this, (xhr) => {
                        xhr.get('/city', {
                            params: {
                                stateid: this.step.stateid,
                                ab: 1, 
                                networkid: this.networkid
                            }
                        }).then((r) => {

                            let data = r.data
                            if(data.response){
                                this.city = data.data
                            }
                            resolve()
                        }).catch(() => {})
                    })
                })
            },

            fetchZone(item, push_empty_item = true){

                return new Promise((resolve) => {

                    this.zones = this.zonei == -1 ? [] : this.zones.slice(0, this.zonei + 1)
                    this.step.zones = this.zonei == -1 ? [] : this.step.zones.slice(0, this.zonei+1)

                    this.$api(this, (xhr) => {
                        xhr.get('/zone', {
                            params: {
                                cityid: this.step.cityid,
                                parent: this.zonei == -1 ? 0 : item,
                                ab: 1,
                                networkid: this.networkid
                            }
                        }).then((r) => {

                            let data = r.data
                            if(data.response){
                                if(data.data.length){

                                    if (push_empty_item) {
                                        this.step.zones.push('')    
                                    }

                                    this.zones.push(data.data)
                                }
                            }
                            resolve()
                        }).catch(() => {})
                    })
                })
            },

            fetchForm() {

                this.$api(this, (xhr) => {
                    xhr.get('/network/form', {
                        params: {
                            networkid: this.profile.networkid
                        }
                    }).then((r) => {

                        let data = r.data
                        if (data.response) {

                            let custom = data.data.custom.map((c) => {
                                c.required   = c.required == 1 ? true : false
                                c.visibility = c.visibility == 1 ? true : false
                                return c
                            })

                            this.customForm = custom
                            this.availableRow = data.data.available == null ? [] : data.data.available

                            let list = this.customForm.filter((c) => c.type == 4)

                            for(let l of list){

                                let dat    = this.step.custom[l.uuid]
                                let option = ''

                                if(Array.isArray(l.options)){
                                    option = l.options.find((o) => {
                                        if(typeof o == 'string'){
                                            return dat
                                        }
                                        return o.option == dat
                                    })
                                }else{
                                    let depends = this.step.custom[l.depends]
                                    if(depends != 'undefined'){
                                        option = l.options[depends.uuid].find((o) => {
                                            if(typeof o == 'string'){
                                                return dat
                                            }
                                            return o.option == dat
                                        })
                                    }
                                }

                                this.step.custom[l.uuid] = option
                                this.computedDepends(l, option)
                            }

                            if(this.profile.isRodolfista){
                                this.fetchFieldState()
                                this.fetchFieldInstitution()
                                this.fetchFieldArea()
                                this.fetchFieldAccount()
                            }
                        }
                    }).catch(() => {})
                })
            },

            fetchFieldAccount(){

                this.$api(this, (xhr) => {
                    xhr.get('/field/account').then(async (r) => {

                        let data = r.data
                        if(data.response){

                            let field_account = data.data
                            
                            this.step.field_state       = field_account.field_state
                            this.step.field_city        = field_account.field_city
                            this.step.field_school      = field_account.field_school
                            this.step.field_institution = field_account.field_institution
                            this.step.field_grade       = field_account.field_grade
                            this.step.field_area        = field_account.field_area

                            await this.fetchFieldCity()
                            await this.fetchFieldSchool()
                        }
                    }).catch(() => {})
                })
            },

            deleteAccountM(){

                this.loadingDelete = true
                this.$api(this, (xhr) => {
                    xhr.delete('/user?reason=' + this.reason).then((r) => {
                        
                        this.loadingDelete = false
                        this.accountMsj = true

                        let data = r.data

                        if(data.response){
                            this.accountColor = 'success'
                            this.accountText = data.message
                            this.$removeItem('xtoken', () => this.$router.push({ path : '/login' }))
                        }else{
                            this.accountColor = 'error'
                            this.accountText = data.message
                        }
                    }).catch(() => {
                        this.loadingDelete = false
                    })
                })
            },

            update(){

                let post = this._.cloneDeep(this.step)
                post.zones = JSON.stringify(post.zones)

                let custom = this._.cloneDeep(post.custom)

                for(let c in custom){
                    if(typeof custom[c] != 'string'){

                        if(custom[c] == undefined){
                            custom[c] = ''
                        }else{
                            custom[c] = Array.isArray(custom[c]) ? custom[c] : custom[c].option
                        }
                    }
                }

                post.custom = JSON.stringify(custom)
                post.inschoolgroup = post.inschoolgroup ? 1 : 0

                this.loadingBtn = true
                this.$api(this, (xhr) => {
                    xhr.post('/account?' + this.$qs.stringify(post)).then((r) => {

                        let data = r.data
                        this.loadingBtn = false
                        this.accountMsj = true
                        
                        if(!data.response){
                            this.accountColor = 'error'
                            this.accountText = data.message
                        }else{
                            this.accountColor = 'success'
                            this.accountText = data.message
                            this.$router.go()
                        }
                    }).catch(() => {
                        this.loadingBtn = false
                    })
                })
            },

            fileChange(e) {

                let files = e.target.files || e.dataTransfer.files
                if (!files.length) return

                let file = files[0]
                let reader = new FileReader();
                
                reader.onload = (e) => this.photo = e.target.result
                reader.readAsDataURL(file)
                this.photoObj = file
                e.target.value = ''
            },

            uploadPhoto(){

                this.loadingPhoto = true

                let form = new FormData()
                form.append('photo', this.photoObj)

                this.$api(this, (xhr) => {
                    xhr.post('/account/photo', form).then((r) => {

                        let data = r.data
                        this.loadingPhoto = false
                        this.accountMsj = true
                        
                        if(!data.response){
                            this.accountColor = 'error'
                            this.accountText = data.message
                        }else{
                            this.accountColor = 'success'
                            this.accountText = data.message
                            this.$router.go()
                        }
                    }).catch(() => {
                        this.loadingPhoto = false
                    })
                })
            },

            removePhoto(){
                this.photo = ''
                this.photoObj = ''
            },

            computedDepends(el, parent){

                let depends = this.customForm.filter((c) => {
                    return c.hasOwnProperty('depends') && c.depends == el.uuid
                })

                for(let depend of depends){

                    let index = this.customForm.findIndex((c) => c.uuid == depend.uuid)
                    if(parent != null && depend.options.hasOwnProperty(parent.uuid)){
                        Vue.set(this.customForm[index], 'coptions', depend.options[parent.uuid])
                    }else{
                        Vue.set(this.customForm[index], 'coptions', [])
                    }
                }
            }
        }
    }

</script>